<!--
 * 项目根组件
 -->
<template>
	<div id="app" name="app">
		<el-container>
			<!-- 顶部导航栏 -->
			<div class="topbar">
				<div class="nav">
					<ul>
						<li v-if="!this.$store.getters.getUser">
							<el-button type="text" @click="login">登录</el-button>
							<span class="sep">|</span>
							<el-button type="text" @click="register = true">注册</el-button>
						</li>
						<li v-else>
							欢迎
							<el-popover placement="top" width="180" v-model="visible">
								<p>确定退出登录吗？</p>
								<div style="text-align: right; margin: 10px 0 0">
									<el-button size="mini" type="text" @click="visible = false">取消</el-button>
									<el-button type="primary" size="mini" @click="logout">确定</el-button>
								</div>
								<el-button type="text"
									slot="reference">{{this.$store.getters.getUser.userName}}</el-button>
							</el-popover>
						</li>
						<li>
							<router-link to="/order">我的订单</router-link>
						</li>
						<li>
							<router-link to="/collect"><i class="el-icon-present"></i>&nbsp;我的收藏夹</router-link>
						</li>
						<li :class="getNum > 0 ? 'shopCart-full' : 'shopCart'">
							<router-link to="/shoppingCart">
								<i class="el-icon-shopping-cart-full"></i> 购物车
								<span class="num">({{getNum}})</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
			<!-- 顶部导航栏END -->

			<!-- 顶栏容器 -->
			<el-header>
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
					active-text-color="#409eff" router>
					<div class="logo">
						<router-link to="/">
							<img class="logoimg" src="./assets/imgs/logo.png" alt />
						</router-link>
					</div>
					<el-menu-item index="/">首页</el-menu-item>
					<el-menu-item index="/goods">全部商品</el-menu-item>
					<el-menu-item index="/about">关于我们</el-menu-item>

					<div class="so">
						<el-input placeholder="请输入搜索内容" v-model="search">
							<el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
						</el-input>
					</div>
				</el-menu>
			</el-header>
			<!-- 顶栏容器END -->

			<!-- 登录模块 -->
			<MyLogin></MyLogin>
			<!-- 注册模块 -->
			<MyRegister :register="register" @fromChild="isRegister"></MyRegister>

			<!-- 主要区域容器 -->
			<el-main>
				<keep-alive>
					<router-view></router-view>
				</keep-alive>
			</el-main>
			<!-- 主要区域容器END -->

			<!-- 底栏容器 -->
			<el-footer>
				<div class="footer">
					<div class="Litebox">
						<div>
							<div>
								<img src="./assets/imgs/f1.jpg" />
								<div>
									<p>精选全球A级鲜花</p>
									<p>18道工序<span class="pl-2">15次质检</span></p>
								</div>
							</div>
							<div>
								<img src="./assets/imgs/f2.jpg" />
								<div>
									<p>国际花艺大师技术支持</p>
									<p>万千搭配<span class="pl-2">高品位养成</span></p>
								</div>
							</div>
							<div>
								<img src="./assets/imgs/f3.jpg" />
								<div>
									<p>全国包邮<span class="pl-2">0℃冷链</span></p>
									<p>极致鲜花保鲜</p>
								</div>
							</div>
							<div>
								<img src="./assets/imgs/f4.jpg" />
								<div>
									<p>12,000,000+位用户见证</p>
									<p>口碑相传<span class="pl-2">五星好评</span></p>
								</div>
							</div>
						</div>
					</div>
					<div class="mod_help">
						<p class="coty">©2019-Now muyuteam.com Inc. All Rights Reserved. 木羽花店</p>
						<a style="color: gray" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19119967号</a>
					</div>
				</div>
			</el-footer>
			<!-- 底栏容器END -->
		</el-container>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";
	import {
		mapGetters
	} from "vuex";

	export default {
		beforeUpdate() {
			this.activeIndex = this.$route.path;
		},
		data() {
			return {
				activeIndex: "", // 头部导航栏选中的标签
				search: "", // 搜索条件
				register: false, // 是否显示注册组件
				visible: false // 是否退出登录
			};
		},
		created() {
			// 获取浏览器localStorage，判断用户是否已经登录
			if (localStorage.getItem("user")) {
				// 如果已经登录，设置vuex登录状态
				this.setUser(JSON.parse(localStorage.getItem("user")));
			}
		},
		computed: {
			...mapGetters(["getUser", "getNum"])
		},
		watch: {
			// 获取vuex的登录状态
			getUser: function(val) {
				if (val === "") {
					// 用户没有登录
					this.setShoppingCart([]);
				} else {
					// 用户已经登录,获取该用户的购物车信息
					this.$axios
						.post("/api/user/shoppingCart/getShoppingCart", {
							user_id: val.user_id
						})
						.then(res => {
							if (res.data.code === "001") {
								// 001 为成功, 更新vuex购物车状态
								this.setShoppingCart(res.data.shoppingCartData);
							} else {
								// 提示失败信息
								this.notifyError(res.data.msg);
							}
						})
						.catch(err => {
							return Promise.reject(err);
						});
				}
			}
		},
		methods: {
			...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
			login() {
				// 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
				this.setShowLogin(true);
			},
			// 退出登录
			logout() {
				this.visible = false;
				// 清空本地登录信息
				localStorage.setItem("user", "");
				// 清空vuex登录信息
				this.setUser("");
				this.notifySucceed("成功退出登录");
			},
			// 接收注册子组件传过来的数据
			isRegister(val) {
				this.register = val;
			},
			// 点击搜索按钮
			searchClick() {
				if (this.search != "") {
					// 跳转到全部商品页面,并传递搜索条件
					this.$router.push({
						path: "/goods",
						query: {
							search: this.search
						}
					});
					this.search = "";
				}
			}
		}
	};
</script>

<style>
	@import "./assets/css/harmonyOS_font.css";
	/* 全局CSS */
	* {
		padding: 0;
		margin: 0;
		border: 0;
		list-style: none;
	}
	
	body, html {
	    font-family: HarmonyOS_Regular, Microsoft YaHei, sans-serif !important;
	    font-size: 14px;
	}

	#app .el-header {
		padding: 0;
	}

	#app .el-main {
		min-height: 300px;
		padding: 20px 0;
	}

	#app .el-footer {
		padding: 0;
	}

	a,
	a:hover {
		text-decoration: none;
	}

	/* 全局CSS END */

	/* 顶部导航栏CSS */
	.topbar {
		height: 40px;
		background-color: #3d3d3d;
		margin-bottom: 20px;
	}

	.topbar .nav {
		width: 100%;
		margin: 0 auto;
	}

	.topbar .nav ul {
		float: right;
		padding-right: 10%;
	}

	.topbar .nav li {
		float: left;
		height: 40px;
		color: #b0b0b0;
		font-size: 14px;
		text-align: center;
		line-height: 40px;
		margin-left: 20px;
	}

	.topbar .nav .sep {
		color: #b0b0b0;
		font-size: 12px;
		margin: 0 5px;
	}

	.topbar .nav li .el-button {
		color: #b0b0b0;
	}

	.topbar .nav .el-button:hover {
		color: #fff;
	}

	.topbar .nav li a {
		color: #b0b0b0;
	}

	.topbar .nav a:hover {
		color: #fff;
	}

	.topbar .nav .shopCart {
		width: 120px;
		background: #424242;
	}

	.topbar .nav .shopCart:hover {
		background: #fff;
	}

	.topbar .nav .shopCart:hover a {
		color: #ff6700;
	}

	.topbar .nav .shopCart-full {
		width: 120px;
		background: #ff6700;
	}

	.topbar .nav .shopCart-full a {
		color: white;
	}

	/* 顶部导航栏CSS END */

	/* 顶栏容器CSS */
	.el-header .el-menu {
		max-width: 1225px;
		margin: 0 auto;
	}

	.el-header .logo {
		padding-left: 1%;
		float: left;
		margin-right: 100px;
	}
	.logoimg{
		width: auto;
		height: 60px;
	}

	.el-header .so {
		margin-top: 10px;
		width: 300px;
		float: right;
	}

	/* 顶栏容器CSS END */

	/* 底栏容器CSS */
	.footer {
		width: 100%;
		text-align: center;
		background: #fff;
		color: #000;
		padding-bottom: 20px;
	}

	.footer>.Litebox {
		width: 100%;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		padding: 3% 0;
	}

	.footer>.Litebox>div {
		width: 80%;
		display: flex;
		flex-wrap: wrap;
		margin-left: 10%;
		justify-content: space-between;
	}

	.footer>.Litebox>div>div {
		width: 25%;
		text-align: center;
		display: flex;
		justify-content: center;
	}

	.footer>.Litebox>div>div>img {
		width: 48px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 5%;
	}

	.footer>.Litebox>div>div>div {
		display: grid;
	}

	.footer .muyuteam {
		height: 50px;
		line-height: 50px;
		margin-top: 20px;
	}

	.footer .mod_help {
		text-align: center;
		color: #888888;
	}

	.footer .mod_help p {
		margin: 20px 0 16px 0;
	}

	.footer .mod_help p a {
		color: #888888;
		text-decoration: none;
	}

	.footer .mod_help p a:hover {
		color: #fff;
	}

	.footer .mod_help p span {
		padding: 0 22px;
	}

	.pl-2 {
		padding-left: 6px;
	}

	/* 底栏容器CSS END */
</style>