//1. 导入 Vue 和 VueRouter 的包
import Vue from 'vue'
import Router from 'vue-router'

//2. 调用Vue.use()函数，把VueRouter安装为Vue的插件
Vue.use(Router)

//3. 创建路由的实例对象
const routes = [{
		path: '/',
		name: 'Index',
		component: () => import('../pages/Index.vue'),
		children: [
			{
				path: '/',
				name: '/',
				component: () => import('../pages/type.vue'),
			}
		]
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../pages/about.vue')
	},
	{
		path: '/goods',
		name: 'goods',
		component: () => import('../pages/goods.vue')
	},
	{
		path: '/details',
		name: 'details',
		component: () => import('../pages/details.vue')
	},
	{
		path: '/collect',
		name: 'collect',
		component: () => import('../pages/collect.vue'),
		meta: {
			requireAuth: true // 需要验证登录状态
		}
	},
	{
		path: '/order',
		name: 'order',
		component: () => import('../pages/order.vue'),
		meta: {
			requireAuth: true // 需要验证登录状态
		}
	},
	{
		path: '/shoppingCart',
		name: 'shoppingCart',
		component: () => import('../pages/shoppingCart.vue'),
		meta: {
			requireAuth: true // 需要验证登录状态
		}
	},
	{
		path: '/confirmOrder',
		name: 'confirmOrder',
		component: () => import('../pages/confirmOrder.vue'),
		meta: {
			requireAuth: true // 需要验证登录状态
		}
	},
	{
		path: '/error',
		name: 'Error',
		component: () => import('../components/Error.vue')
	}
]

const router = new Router({
	// base: '/dist',
	// mode: 'history',
	routes
})

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}


//4. 向外共享路由的实例对象
export default router