import Vue from 'vue'
import Vuex from 'vuex'

// 用来存储数据
const state = {
	num: 9527
}
// 响应组件中的事件
const actions = {

}
// 操作数据
const mutations = {

}
// 用来将state数据进行加工
const getters = {

}

import user from './modules/user'
import shoppingCart from './modules/shoppingCart'
Vue.use(Vuex)
// 新建并暴露store
export default new Vuex.Store({
	strict: true,
	modules: {
	  user,
	  shoppingCart
	}
})